/* eslint-disable @typescript-eslint/ban-ts-comment */
import ENVIRONMENT from 'core/environment';
import { ampli } from 'core/ampli';

// @ts-ignore
const ampliEnv = ENVIRONMENT === 'prod' ? 'prodteamsnapforbusiness' : 'devteamsnapforbusiness';

ampli.load({
  // @ts-ignore
  environment: ampliEnv,
  client: { configuration: { defaultTracking: false } },
  // @ts-ignore
  disabled: ENVIRONMENT === 'local',
});
