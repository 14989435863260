import * as React from 'react';
import { useFormIdSelector, useOrgIdSelector } from 'state/order/orderSlice';

export const Cookies = () => {
    const orgId = useOrgIdSelector();
    const formId = useFormIdSelector();
    React.useEffect(() => {
        if (formId && orgId) {
            try {
              //set cookie for order id
              document.cookie = `ts_registration_form_id=${formId}; path=/`;
              document.cookie = `ts_organization_id=${orgId}; path=/`;
            } catch (e) {
              console.log('Error setting cookie', e);
            }
        }
    }, [formId, orgId]);

  return null;
}
