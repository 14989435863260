/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';

import { OpenAPI } from 'core/api';
import { API_URL, SENTRY_URL } from 'core/constants';
import ENVIRONMENT from 'core/environment';

import { Root } from 'pages/root';
import RejectOfferPage from 'pages/RejectWaitlistOfferPage/RejectWaitlistOfferPage';
import ExpiredWaitlistOfferPage from 'pages/ExpiredWaitlistOfferPage/ExpiredWaitlistOfferPage';

import { store } from './state/store';
import '@teamsnap/snap-ui/build/snap-ui.min.css';
import '@teamsnap/teamsnap-ui/src/css/teamsnap-ui.scss';
import './core/css/root.scss';
import NotFound from 'pages/NotFound';
import { Cookies } from 'components/Cookies';
import 'core/ampliude-init';

OpenAPI.BASE = API_URL;
OpenAPI.WITH_CREDENTIALS = true;

window.onload = async () => {
  // @ts-ignore
  if (ENVIRONMENT === 'local' || window?.location?.host?.includes('localhost')) {
    return;
  }

  Sentry.init({
    dsn: SENTRY_URL,
    environment: ENVIRONMENT,
  });
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/order/:orderId" element={<Root />} />
          <Route path="/order/:orderId/:path" element={<Root />} />
          <Route path="/accept/:offerToken" element={<Root />} />
          <Route path="/accept/:offerToken/:path" element={<Root />} />
          <Route path="/decline/:offerToken" element={<Root />} />
          <Route path="/decline/:offerToke/:path" element={<Root />} />
          <Route path="/reject" element={<RejectOfferPage />} />
          <Route path="/expired" element={<ExpiredWaitlistOfferPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <Cookies />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
