import * as React from 'react';
import './ConfirmationExperiment.scss';

export const ConfirmationExperiment = () => {

  const handleClick = () => {
    // call amplitude click event
    // call ampli.flush()
    // redirect to desired url
  };

  return (
    <div
      id="staging-reg-2-poc"
      className="amplitude-experiment-container"
      style={{ display: 'none' }}
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleClick();
        }
      }}
    >
      <div />
    </div>
  );
};
